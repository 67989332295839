<template>
  <div id="bb">
    <div style="color: white">Admin page</div>

    <div>
      <router-link to="/admin/luoghi/">Luoghi</router-link>
    </div>
    <div>
      <router-link to="/admin/eventi/">Eventi</router-link>
    </div>
    <div>
      <router-link to="/admin/sub_categories/">Categorie</router-link>
    </div>
    <div>
      <router-link to="/admin/parlanodinoi/">Parlano di noi</router-link>
    </div>
  </div>
</template>





<script>
export default {
  async mounted() {},
  methods: {},
  data() {
    return {};
  },
};
</script>
